export const SEMI_FINISHED_PROVIDER = 'OUTLABEL';

export const DATE_FORMAT = {
  BRAZILIAN: 'DD/MM',
  BRAZILIAN_LETTERS: 'DD/MMM',
  AMERICAN: 'MM/DD/YY'
}

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  EXP: 'EXP',
  REGULAR: 'REGULAR'
}

export const LOAD_TYPES = {
  FINISHED: 'ACABADO',
  SEMI_FINISHED: 'SEMI ACABADO',
  RAW: 'MATÉRIA PRIMA',
  EMPTY: 'VAZIO'
}

export const LOAD_TYPES_SERVER = {
  FINISHED: 'FINISHED',
  SEMI_FINISHED: 'SEMI_FINISHED',
  RAW: 'RAW',
  EMPTY: 'EMPTY'
}

export const LOAD_TYPES_ABBREVIATION = {
  FINISHED: 'AC',
  SEMI_FINISHED: 'SA',
  RAW: 'MP',
  EMPTY: 'VAZIO'
}

export const LOAD_TYPES_UI_TO_SERVER = {
  'ACABADO': 'FINISHED',
  'SEMI ACABADO': 'SEMI_FINISHED',
  'MATÉRIA PRIMA': 'RAW',
  'VAZIO': 'EMPTY'
}

export const DASHBOARD_TABLE_HEADERS = [
    { title: 'Cliente', key: 'customerOrProvider' },
    { title: 'Descrição', key: 'description'},
    { title: 'PV', key: 'batch', align: 'center'},
    { title: 'Fileira', key: 'rowName' },
    { title: 'Local', key: 'name' },
    { title: 'Volumes', key: 'volume', align: 'center'},
    { title: 'Alteração', key: 'updatedAt', align: 'center' },
    { title: 'Entrega', key: 'deliveryDate', align: 'center' },
    { title: 'Carga', key: 'loadType', align: 'center' }
];

export const ROW_TABLE_HEADERS = [
    { title: 'Fileira', key: 'name' },
    { title: 'Criado', key: 'createdAt' },
    { title: 'Locais', key: 'spots', align: 'center' },
    { title: 'Vazios', key: 'emptyCount', align: 'center' }
];
  
  export const SPOT_TABLE_HEADERS = [
      { title: 'Local', key: 'name' },
      { title: 'Fileira', key: 'rowName', align: 'start' },
      { title: 'Cliente | Fornecedor', key: 'customerOrProvider', align: 'start' },
      { title: 'Descrição', key: 'description', align: 'start' },
      { title: 'Volumes', key: 'volume', align: 'center' },
      { title: 'Quantidade', key: 'quantity', align: 'center' },
      { title: 'Carga', key: 'loadType', align: 'center' },
      { title: 'Entrega', key: 'deliveryDate', align: 'center' },
      { title: 'Ações', key: 'actions', align: 'center', sortable: false}
  ];