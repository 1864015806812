<template>
  <v-card>
    <v-tabs
      v-model="tab"
      align-tabs="center"
      color="red-darken-4"
    >
      <v-tab :value="1">Geral</v-tab>
      <v-tab 
        v-if="!hideTabs"
        :value="2"
      >Fileiras</v-tab>
      <v-tab 
        v-if="!hideTabs"
        :value="3"
      >Espaços</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item :value="1">
        <GeneralTable />
      </v-tabs-window-item>
      <v-tabs-window-item :value="2">
        <RowsTable />
      </v-tabs-window-item>
      <v-tabs-window-item :value="3">
        <SpotTable />
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card>
</template>

<script>
import GeneralTable from './General/GeneralTable.vue';
import RowsTable from '@/components/Rows/RowsTable.vue'
import SpotTable from '@/components/Spot/SpotTable.vue'
import { USER_ROLES } from '@/helpers/constants/app.constants';

import { mapActions, mapState } from 'vuex';

export default {
  components: { GeneralTable, RowsTable, SpotTable },
  data: () => ({
    tab: null,
  }),

  computed: {
    ...mapState('row', ['rows']),
    ...mapState('user', ['user']),
    
    hideTabs() {
      return this.user.roleCode === USER_ROLES.REGULAR
    }
  },

  async mounted() {
    if (!Object.keys(this.user).length) {
      const { success } = await this.getUser();

      if (!success) {
        this.$router.push({ name: 'login' })
      }
    }

    if (!this.rows.length) {
      this.initRows();
    }
  },

  methods: {
    ...mapActions('row', ['initRows']),
    ...mapActions('user', ['getUser'])
  }
}
</script>