<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn 
        v-if="isAdminUser && !fromQRCode"
        v-bind="activatorProps"
        variant="text"
        icon="mdi-plus"
      />
    </template>

    <v-card
      :title="title"
    >
      <v-form ref="form">
        <v-row class="mx-2">
          <v-col cols="12" md="6" class="d-flex flex-column ga-3">
            <v-select
              v-model="form.rowName"
              label="Selecione Fileira"
              :items="rowList"
              :rules="[v => !!v || 'Fileira obrigatória']"
              flat
              required
              hide-details
              density="compact"
              :disabled="isUpdateMode && !fromQRCode"
            />

            <v-select
              v-if="form.rowName"
              v-model="form.name"
              label="Selecione Espaço"
              :items="spotList"
              :rules="[v => !!v || 'Espaço obrigatória']"
              flat
              required
              hide-details
              density="compact"
              :disabled="(!form.rowName || isUpdateMode) && !fromQRCode"
            />

            <v-radio-group v-model="form.loadType">
              <v-radio
                v-for="(type, index) of LOAD_TYPES"
                :key="index"
                :label="type"
                :value="type"
              />
            </v-radio-group>

            <v-btn
              v-if="form.loadType === LOAD_TYPES.FINISHED"
              @click="dateDialog = true"
            >
              <v-icon>mdi-pencil</v-icon>
              Previsão Saída
            </v-btn>

            <div v-if="form.deliveryDate && form.loadType === LOAD_TYPES.FINISHED" class="w-100">
              <v-chip size="x-large" label color="primary" prepend-icon="mdi-calendar-month" class="d-flex justify-center w-100">
                {{ `Saída - ${getFormattedDate(form.deliveryDate)}` }}
              </v-chip>
            </div>
          </v-col>

          <v-col 
            v-if="form.loadType && form.loadType !== LOAD_TYPES.EMPTY"
            cols="12" 
            md="6" 
            class="d-flex flex-column ga-3"
          >
            <v-text-field
              v-model="form.customerOrProvider"
              label="Cliente | Fornecedor"
              hide-details
              :disabled="!form.rowName || form.loadType === LOAD_TYPES.SEMI_FINISHED"
            />

            <v-text-field
              v-model="form.description"
              label="Descrição"
              hide-details
              :disabled="!form.rowName"
            />

            <v-text-field
              v-if="form.loadType === LOAD_TYPES.FINISHED"
              v-model="form.productCode"
              label="Código Produto"
              hide-details
              :disabled="!form.rowName"
            />

            <v-text-field
              v-model="form.batch"
              label="PV"
              hide-details
              :disabled="!form.rowName"
            />

            <v-text-field
              v-model.number="form.volume"
              label="Volumes"
              hide-details
              :disabled="!form.rowName"
            />

            <v-text-field
              v-model.number="form.quantity"
              label="Quantidade"
              hide-details
              :disabled="!form.rowName"
            />

              <v-dialog 
                v-model="dateDialog"
              >
              <v-row justify="center">
                <v-date-picker
                  v-model="form.deliveryDate"
                  color="red darken-4"
                  flat
                ></v-date-picker>
              </v-row>
            </v-dialog>
          </v-col>
        </v-row>
      </v-form>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn 
          :disabled="isLoading"
          @click="closeDialog"
        >
          Cancelar
        </v-btn>


        <v-btn
          v-if="!isLoading"
          @click="onCreate"
          :disabled="disableConfirmButton"
        >
          Confirmar
        </v-btn>
        <v-progress-circular
          v-else
          :size="35"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-card> 
  </v-dialog> 
</template>

<script>
import { DATE_FORMAT, LOAD_TYPES, SEMI_FINISHED_PROVIDER, LOAD_TYPES_SERVER } from '@/helpers/constants/app.constants';
import { mapState, mapActions, mapGetters } from 'vuex';

import dayjs from 'dayjs';

export default {
  props: {
    spotObj: {
      type: Object,
      default: () => ({})
    },

    fromQRCode: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    dialog: false,
    dateDialog: false,
    snackbar: {
      active: false,
      text: 'Sucesso ao criar local.',
      color: 'green'
    },
    isLoading: false,

    form: {
      name: '',
      customerOrProvider: '',
      description: '',
      batch: '',
      productCode: '',
      volume: 0,
      quantity: 0,
      deliveryDate: null,
      loadType: '',
      rowName: null,
    }
  }),

  computed: {
    ...mapState('row', ['rows']),
    ...mapState('spot', ['spots']),
    ...mapGetters('user', ['isAdminUser']),

    LOAD_TYPES() {
      return LOAD_TYPES;
    },
    
    rowList() {
      return this.rows.map((row) => {
        return { title: row.name, value: row.name }
      })
    },

    spotList() {
      return this.spots
      .filter(
        (spot) =>
          spot.rowName === this.form.rowName &&
          spot.loadType === LOAD_TYPES_SERVER.EMPTY
      )
      .map((spot) => ({ id: spot.id, title: spot.name, value: spot.name }));
    },

    spotNameFull() {
      return `${this.form.rowName}-${this.form.name}`;  
    },

    allowedName() {
      return this.isUpdateMode ? false : !!this.spots.find(spot => spot.name === this.spotNameFull);
    },

    isUpdateMode() {
      return !!Object.keys(this.spotObj).length
    },

    title() {
      return this.isUpdateMode ? `Alterar Local` : 'Criar novo Local?'
    },

    disableConfirmButton() {
      if (!this.form.name || !this.form.rowName && this.allowedName) {
        return true;
      }

       if (this.form.loadType !== this.LOAD_TYPES.EMPTY) {
        if (!this.form.customerOrProvider || !this.form.description || !this.form.batch || !this.form.volume || !this.form.quantity) {
          return true
        }

        if (this.form.loadType === this.LOAD_TYPES.FINISHED) {
          return !this.form.productCode || !this.form.deliveryDate;
        }
       }

      return false;
    }
  },

  watch: {
    'form.loadType'(newValue, oldValue) {
      if (oldValue === this.LOAD_TYPES.SEMI_FINISHED) {
        this.form.customerOrProvider = ''
      }

      if (newValue !== this.LOAD_TYPES.FINISHED) {
        this.form.productCode = '';
        this.form.deliveryDate = null;
      }

      if (newValue === this.LOAD_TYPES.EMPTY) {
        this.resetForm();
      }

      if (newValue === this.LOAD_TYPES.SEMI_FINISHED) {
        this.form.customerOrProvider = SEMI_FINISHED_PROVIDER
      }
    },

    deliveryDate() {
      this.dateDialog = false;
    },

    spotObj() {
      if (this.isUpdateMode) {
        this.dialog = true;
      }
    },

    dialog(newValue) {
      if (newValue) {
        this.isUpdateMode ? this.initUpdateMode() : this.form.loadType = this.LOAD_TYPES.EMPTY
      } else {
        this.closeDialog();
      }
    }
  },

  methods: {
    ...mapActions('spot', ['addSpot', 'updateSpot']),

    getLoadTypeKey(value) {
      return Object.keys(this.LOAD_TYPES).find(key => this.LOAD_TYPES[key] === value);
    },

    resetForm() {
      this.form = {
        name: this.isUpdateMode ? this.spotObj.name : '',
        customerOrProvider: '',
        description: '',
        batch: '',
        productCode: '',
        volume: 0,
        quantity: 0,
        deliveryDate: null,
        loadType: this.LOAD_TYPES.EMPTY,
        rowName: this.isUpdateMode ?  this.spotObj.rowName : '',
      }
    },

    closeDialog() {
      this.$emit('close', this.snackbar);
      this.dialog = false;
      this.resetForm();
    },

    getFormattedDate(date) {
      return dayjs(date).format(DATE_FORMAT.BRAZILIAN_LETTERS);
    },

    initUpdateMode() {
      this.form = {
        name: this.spotObj.name,
        customerOrProvider: this.spotObj.customerOrProvider,
        description: this.spotObj.description,
        batch: this.spotObj.batch,
        productCode: this.spotObj.productCode,
        volume: this.spotObj.volume,
        quantity: this.spotObj.quantity,
        deliveryDate: this.spotObj?.deliveryDate && !!this.spotObj?.description ? new Date(this.spotObj?.deliveryDate) : new Date(),
        loadType: this.LOAD_TYPES[this.spotObj.loadType],
        rowName: this.spotObj.rowName,
      }
    },

    async onCreate() {
      const { valid } = await this.$refs.form.validate()

      if (!valid) return;

      if (this.allowedName) {
        return;
      }

      const payload = { 
        ...this.form,
        name: this.isUpdateMode && !this.fromQRCode ? this.form.name.substring(2) : this.form.name,
        description: this.form.description?.toUpperCase(),
        customerOrProvider: this.form.customerOrProvider?.toUpperCase(),
        productCode: this.form.productCode?.toUpperCase(),
        batch: this.form.batch?.toUpperCase(),
        loadType: this.getLoadTypeKey(this.form.loadType)
      }

      this.isLoading = true;
      if (!this.isUpdateMode) {
        const { success } = await this.addSpot(payload)

        if (success) {
          this.snackbar.active = true;
        } else {
          this.snackbar.text = 'Erro ao criar local.',
          this.snackbar.color = 'red',
          this.snackbar.active = true;
        }
      } else {
        const { success } = await this.updateSpot(payload);

        if (success) {
          this.snackbar.text = 'Local atualizado.',
          this.snackbar.color = 'green',
          this.snackbar.active = true;
        } else {
          this.snackbar.text = 'Erro ao atualizar local.',
          this.snackbar.color = 'red',
          this.snackbar.active = true;
        }
      }
      this.isLoading = false;


      this.closeDialog();
    }
  }
}
</script>