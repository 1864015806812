<template>
  <v-card 
    flat
    class="rounded-lg ma-1"
  >
    <v-card-title class="d-flex align-center pe-2 ga-1">
      Fileiras
      
      <CreateRowDialog 
        v-if="isAdminUser"
        @display-snackbar="onDisplaySnackbar"
      />
    </v-card-title>

    <v-divider></v-divider>
    <v-data-table 
      v-model:search="search" 
      :items="items"
      :headers="headers"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{  getBrazilianFormatDate(item.created_at) }}
      </template>
    </v-data-table>

    <v-snackbar :color="snackbar.color" v-model="snackbar.active" :timeout="4000">
      <div color="white">{{ snackbar.text }}</div>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CreateRowDialog from '@/components/Rows/CreateRowDialog';

import { ROW_TABLE_HEADERS, DATE_FORMAT, LOAD_TYPES_SERVER } from '@/helpers/constants/app.constants';
import { mapGetters, mapState } from 'vuex';

import dayjs from 'dayjs';

export default {
  components: { CreateRowDialog },
  
  data: () => ({
    search: '',
    snackbar: {
      active: false,
      text: 'Sucesso ao criar fileira.',
      color: 'green'
    }
  }),

  computed: {
    ...mapState('row', ['rows']),
    ...mapState('spot', ['spots']),
    ...mapGetters('user', ['isAdminUser']),

    items() {
      return this.rows.map((row) => {
        const filteredSpots = this.spots.filter(spot => spot.rowName === row.name);
        const spotsCount = filteredSpots.length;
        const emptyCount = filteredSpots.filter(spot => {
          return spot.loadType === LOAD_TYPES_SERVER.EMPTY
        }).length;

        return { 
          ...row,
          createdAt: this.getBrazilianFormatDate(row.createdAt),
          spots: spotsCount,
          emptyCount
        };
      });
    },

    headers() {
      return ROW_TABLE_HEADERS;
    }
  },

  methods: {
    getBrazilianFormatDate(date) {
      return dayjs(date).format(DATE_FORMAT.BRAZILIAN);
    },

    onDisplaySnackbar(snackbar) {
      this.snackbar = snackbar;
    }
  }
}
</script>